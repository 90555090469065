.organizationSignup-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 420px;
  margin: 40px auto;
}

.organizationSignup-form .heading {
  color: var(--text-color);
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px; /* 125% */
  margin-top: 30px;
}

.organizationSignup-form .subheading {
  text-align: center;
/* Paragraph Large */
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-top: 6px;
}

.organizationSignup-form .email-input-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
}

.steps-bar {
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 8px;
}

.circle-bar { 
  position: relative;
  width: 30.9px;
  height: 30.9px;
  border-color: #B5BAC4 !important;
  border-width: 8px;
  border-style: solid;
  border-radius: 100%;
}

.circle-bar.done::after {
  position: absolute;
  content: '';
  top: 0;
  left: 4px;
  background: #315285;
  display: inline-block;
  transform: rotate(45deg);
  height: 12px;
  width: 6px;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
}

.circle-bar.done {
  background: #315285;
}

.circle-bar.done, .circle-bar.current {
  border-color: #315285 !important;
}

.line-bar {
  background-color: #B5BAC4;
  width: 124.528px;
  height: 3.866px;
}

.line-bar.done {
  background-color: #315285;
}

.steps-label {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.step-label {
  color: #B5BAC4;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; /* 125% */
  max-width: 80px;
  margin-bottom: 34px;
}

.step-label.done {
  color: #315285;
}

.step-label:first-of-type {
  margin-left: 15px;
}

.step-label:last-of-type {
  margin-right: 15px;
}

.organizationSignup-form .form-label {
  font-size: 14px !important;
}

.email-check-icon {
  color: #495057;
}

.timezone-select {
  padding: 8px;
}

.nextStep-btn {
  height: 46px;
  border-radius: 6px;
}

.nextStep-btn:focus {
  background:#2e663c;
  box-shadow: 0 0 0 0.2rem rgba(66, 146, 86, 0.4);
}

.back-link {
  color: #4469A1;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px; /* 150% */
  margin-top: 24px;
  cursor: pointer;
}

.selectStorage-content {
  width: 450px;
}

.storage-options-container {
  display: flex;
  gap: 18px;
  margin: 30px 0 40px;
}

.storage-option-input {
  opacity: 0 !important;
  width: 225px !important;
  height: 168px !important;
}

.storage-option {
  top: 0;
  left: 0;
  z-index: -1;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 225px;
  height: 168px;
  padding: 24px 28px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--dark-dark-06, #E0E1E6);
  background: #FFF;
  box-shadow: 0px 0px 3px 0px #0000001c;
}

.storage-option.selected {
  color: #495057;
  background-color: #fff;
  outline: 0;
  box-shadow: rgba(66, 146, 86, 0.25) 0 0 0 0.2rem;
  border-color: #429256;
}

.aws-guide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 63px;
  flex-shrink: 0;
  margin-bottom: 30px;
  border-radius: 4px;
  background: var(--dark-dark-07, #ECECF0);
  box-shadow: 0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 1px 1px 0px rgba(9, 30, 66, 0.25);
}

.inviteMembers-input {
  position: relative;
  display: flex;
  gap: 12px;
  margin-top: 30px;
  width: 100%;
}

.invitedEmail-validity {
  display: flex;
  justify-content: flex-start;
  font-size: 13px;
  margin: -15px 0 0 18px;
}

.inviteMembers-input .btn-outline-success {
  color: #53A551;
  border-color: #53A551;
}

.inviteMembers-input .btn-outline-success:hover {
  color: white;
  background: #53A551;
}


.accountType-dropdown {
  background: transparent;
  font-size: 14px;
  font-weight: 600;
  border: 0;
  width: 120px;
  margin-right: 8px;
}

.invited-emails {
  width: 422px;
  height: 127px;
  padding: 4px 0;
  margin: 12px 0 20px;
  border-radius: 6px;
  background: #F4F6F7;
  overflow: scroll;
}

.invited-email {
  display: flex;
  justify-content: space-between;
  align-items: center ;
  color: #0E1E40;
  background: transparent;
  width: 100%;
  height: 36px;
  padding: 8px 12px;
}

.invited-email:hover, .invited-email.selected {
  background: #E0E1E6;
}

.x-btn {
  position: relative;
}

.x-btn::after {
  position: absolute;
  right: 0;
  top: 0;
  content: "\d7";
  font-size: 26px;
  font-weight: 300 !important;
  line-height: 0;
}

@keyframes onAutoFillStart {
}

@keyframes onAutoFillCancel {
}

.inviteMembers-input input:-webkit-autofill {
  animation-name: onAutoFillStart;
  transition: background-color 50000s ease-in-out 0s;
}

.inviteMembers-input input:not(:-webkit-autofill) {
  animation-name: onAutoFillCancel;
}