.rs-theme-light,
:root {
  --rs-state-focus-shadow: 0 0 0 3px rgba(66, 146, 86, 0.222);
  --rs-input-focus-border: #429256;
}

.rs-picker-menu {
  z-index: 1400;
}

/* Input Box */
.rs-picker-daterange.rs-picker,
.rs-picker-date.rs-picker {
  width: 100%;
  height: 40px !important;
  border-radius: 4px !important;
}

.rs-picker-toggle {
  border-radius: 4px;
}

.rs-picker-toggle,
.rs-picker-toggle .rs-stack {
  height: 100% !important;
}

.rs-picker-toggle-value {
  font-weight: 400 !important;
}

.rs-picker-toggle-caret.rs-icon {
  margin-top: 4px;
  color: #429256;
  font-size: large;
}

.rs-picker-toggle-clean.rs-btn-close {
  margin-top: 4px;
}

.rs-picker-toggle-placeholder,
.rs-picker-toggle-textbox {
  font-size: 16px !important;
  font-weight: 400;
}

.rs-picker-toggle-value,
.rs-picker-toggle-textbox {
  color: #0E1E40 !important;
  font-size: 14px;
  font-weight: 500;
}

.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  right: 12px;
  top: 5px;
}


/* Main Calendar */

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
.rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content {
  background-color: #213D69;
  border-radius: 50%;
}

.rs-calendar-table-cell-selected:hover .rs-calendar-table-cell-content,
.rs-calendar-month-dropdown-cell-active:hover .rs-calendar-month-dropdown-cell-content {
  background-color: #213D69 !important;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  box-shadow: inset 0 0 0 1px #213D69;
}

.rs-calendar-table-cell-in-range,
.rs-picker-daterange-menu .rs-calendar-table-cell-selected {
  background: #D8E6FC;
}

.rs-calendar-table-cell-content:hover,
.rs-calendar-month-dropdown-cell-content:hover {
  background: #D8E6FC !important;
}

.rs-calendar-table-cell-selected-start {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.rs-calendar-table-cell-selected-end {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-day {
  color: #C5C6C7;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-day,
.rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content {
  color: #fff !important;
}

.rs-calendar-table-cell-day,
.rs-calendar-month-dropdown-cell-content,
.rs-calendar-header-month-toolbar .rs-icon {
  color: #0E1E40;
}

.rs-calendar-table-cell-content:hover .rs-calendar-table-cell-day {
  color: #1675e0;
}

.rs-calendar-header-title-date {
  color: #0E1E40 !important;
  font-weight: 600;
}

.rs-calendar-month-dropdown-row {
  padding-left: 56px;
}

.rs-calendar-month-dropdown-year-active {
  background-color: #ECECF0;
  color: #575757;
  border-radius: 4px;
}

.rs-calendar-month-dropdown-year {
  margin-right: 2px;
  padding: 2px 4px;
}

.rs-picker-daterange-header {
  display: none;
}

.rs-picker-daterange-calendar-group {
  margin-top: 16px;
}

.rs-picker-daterange-menu .rs-calendar:first-child {
  border-right: 0;
}

.rs-picker-toolbar.rs-stack {
  border-top: 0;
  padding: 0;
}


/* Sidebar predefined dateranges */

.rs-picker-daterange-predefined {
  height: 344px;
  padding: 16px;
  border-right: 1px solid #ECECF0;
}

.rs-picker-daterange-predefined .rs-stack-item {
  width: 100%;
  border: 1px solid #E0E1E6;
  border-radius: 4px;
  margin-bottom: 4px;
}

.rs-picker-daterange-predefined .rs-stack-item:hover {
  width: 100%;
  background-color: #ECECF0;
}

.rs-btn-link {
  width: 100%;
  text-align: left;
  color: #0E1E40 !important;
  text-decoration: none !important;
}


/* OK Button */
.rs-btn-primary {
  background-color: #429256 !important;
  padding: 8px 16px !important;
  margin: 0 16px 16px 0;
}

.rs-btn-primary:hover {
  background-color: #2c643c !important;
}