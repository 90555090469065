.user-menu-sidenav {
  display: flex;
  color: #1d1c1d;
  cursor: pointer;
  border-bottom: 1px solid #ECECF0;
  padding-bottom: 15px;
  margin: 8px 24px 0;
}

.workspacelist {
  padding: 0 !important;
}

.content {
  flex: 1;
  padding: 0 8px;
}

.content div {
  text-transform: capitalize;
}

.link-text {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #213D69;
  padding: 8px 0 8px 24px !important;
}

.active-link {
  font-weight: 600;
  background: #ECECF0;
  border-right: 5px solid #213D69;
  padding-right: 10px;
}

.content h3 {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #7D8698;
}

hr {
  background: #ECECF0;
  margin: 8px 0 !important;
}

.sidenav {
  margin-top: var(--header-height);
  padding: 20px 0 0;
  height: calc(100% - 20px - var(--header-height));
  position: fixed;
  z-index: 1;
  left: 0;
  width: var(--sidenav-width);
  background-color: #FBFBFC;
  overflow-x: hidden;
  /* padding-top: 60px; */
  transition: 0.5s;
}

.nav-contents h3 {
  padding: 24px 24px 0;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #7D8698;
}

.nav-contents ul:last-child {
  margin-bottom: 0;
}

.nav-contents ul {
  list-style: none;
  padding: 0;
  margin-bottom: 1.5rem;
  margin-left: 0;
}

.user-menu .user-menu-contents {
  color: #1d1c1d;
  font-weight: 700;
  height: 4rem;
  display: block;
  cursor: pointer;
  padding-top: 20px;
}

.user-menu-avatar {
  height: 32px;
}

.user-menu-avatar .member-image-thumb {
  margin-top: 0px;
  width: 32px;
  height: 32px;
  background-size: 100%;
  background-repeat: no-repeat;
  display: inline-block;
  position: relative;
  background-image: url(https://secure.gravatar.com/avatar/8ec31bd….jpg?s=7…d=https%3A%2F%2Fa.slack-edge.com%2Fdf10d%2Fimg%2Favatars%2Fava_0024-72.png);
}

.wsUser {
  border-radius: 50%;
}

.user-menu-name b.uname {
  font-weight: 400;
}

.user-menu-contents h3 {
  text-transform: uppercase;
  color: #616061;
  letter-spacing: .5px;
  font-size: .8rem;
}

.user-menu-name b {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
}

.user-menu-contents .user-menu-name {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 175px;
  margin-top: 2px;
  margin-bottom: -4px;
  color: #1d1c1d;
  text-transform: capitalize;
}

.link-text,
.link-text:visited,
.link-text:hover {
  text-decoration: none;
  color: #213D69;
}

.link-item:hover {
  text-decoration: none;
  color: #213D69;
}

.admin-nav .link-item .subMenuUl .subMenuItem:first-child::before {
  height: 18px;
  transform: none;
}

.admin-nav li:hover {
  color: #213D69;
  font-weight: 600;
  background: #ECECF0;
  /* border-right: solid 5px #213D69; */
  box-shadow: inset -5px 0 0 0 #213D69;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 18px;
  }
}

.administration {
  margin-bottom: 10px;
  font-size: 80%;
}
