@media screen and (max-width: 950px) {
  .view-activity {
    .small-snapshot-grid {
      gap: 10px;
      grid-template-columns: repeat(3, 1fr);
      padding-top: 5px;
    }
  }
}

.view-activity {
  overflow: scroll;
  background-color: #FBFBFC;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toolbar .right-section {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 16px;
}

.snapshot-row {
  border-left: 1px solid var(--dark-7);
  padding-left: 11px;
  padding-bottom: 45px;
  margin: 0 24px
}

.snapshot-row:first-child {
  margin-top: 24px;
}

.snapshot-row:last-child {
  margin-bottom: 24px;
}

.snapshot-grid {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(6, 1fr);
  padding-top: 5px;
}

.snapshot {
  border: 1px solid var(--dark-7);
  border-radius: 4px;
}

.snapshot-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px 6px;
}

.webcam-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 6px;
}

.snap-text {
  font-size: 12px;
  color: var(--dark-3);
}

.activity-bar-parent {
  width: 100%;
  height: 5px;
  border-radius: 20px;
  margin: 10px 0;
  background-color: var(--dark-7);
}

.activity-bar {
  height: 5px;
  border-radius: 20px;
}

.time-bubble {
  height: 15px;
  width: 15px;
  border-radius: 8px;
  border: 1px solid var(--dark-7);
  background: #fff;
}

.no-activity-placeholder {
  height: 40px;
  background-color: var(--dark-8);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.screens-btn {
  background-color: #fff;
  border-radius: 50px;
  padding: 5px 13px;
  box-shadow: 0px 6px 18px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31);
  color: var(--blue-3);
  width: fit-content;
  margin: auto;
  z-index: 1;
  font-weight: 600;
}

.snapshot-img-view {
  cursor: pointer;
}

.snapshot-img-cover {
  position: absolute;
  width: 100%;
  background: rgba(0, 0, 0, 0.0);
  height: 100%;
  transition: background-color 300ms linear;
}

.snapshot-img-view:hover {
  .snapshot-img-cover {
    background: rgba(0, 0, 0, 0.50);
  }
}

.snapshot-img-blur {
    filter: blur(2px);
}

.subheading-container {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

.activity-table {
  margin-bottom: 0 !important;
}

.table-row {
  display: grid;
  grid-template-columns: 200px repeat(8, 1fr);
}

.uncollapsed {
  height: 72px;
}

.table-cell {
  background-color: #fff;
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 0;
  border-right: 1px solid var(--dark-6) !important;
  border-bottom: 1px solid var(--dark-6) !important;
}

.table-cell:last-child {
  border-right: none !important;
}

.selected-date {
  background-color: rgba(83, 165, 81, 0.1)
}

.selected-date .dayname-date,
.selected-date .day-date,
.activity-table .high-activity {
  color: var(--green-3);
}

.activity-table .low-activity {
  color: var(--red-1);
}

.activity-table .table-value {
  color: var(--primary);
}

.table-header,
.name,
.dayname-date,
.day-date {
  color: var(--text-color);
}

.dayname-date {
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
}

.subdate-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
}

.day-date {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 133.333% */
}

.month-date {
  color: #7D8698;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
}

.avatar-user {
  padding: 10px;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  color: white;
  text-align: center;
  background-color: #53A55116;
  color: #53A551;
}

.name-user {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.buttons-container {
  height: 73px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 2px solid #E0E1E6;
}

.toggle-button {
  margin: 4px 28px 0 auto;
}

.collapse-button {
  width: 24px;
  background-color: #fff;
  border-radius: 2px;
  border: 2px solid #546077;
  padding: 0;
}