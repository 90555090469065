.avatar {
   background-color: #F4F6F7;
   position: relative;
   overflow:hidden;
}

.overlay {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  background: #0009;
  transition: all ease .5s;
}

.avatar:hover .overlay {
  visibility: visible;
  opacity: 1;
}

.avatar-btn-group {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.avatar-related-btn {
  position: relative;
  overflow: hidden; 
  width: 42px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.bg-avatar-btn {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.25;
}
