.team-engagement-header-actions {
  display: flex;
  gap: 24px;
}

.team-engagement-body {
  padding: 8px 0;
  position: relative;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.team-header-manage-member {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: var(--dark-1, #0E1E40);
  height: 40px;
}

.input-wrapper{
  padding-bottom: 0px;
}

.create-team-modal .modal-header {
  padding: 0!important;
}

.create-team-modal .modal-body-customized {
  padding: 0;
  position: relative;
  min-height: 130px;
  margin-top: 16px;
}

.create-team-modal .modal-footer-customized {
  border: 0;
  margin-top: 16px;
}

.create-team-modal .modal-body div:last-child {
  padding-bottom: 0 !important;
}

.edit-modal.loader-container {
    left: 50%;
    top: 50px;
}

.card {
  flex: 1;
}

.card-container {
  display: flex;
  flex-flow: column;
  padding: 8px 10px;
  height: 100%;
  justify-content: space-between;
}

.card-row {
  width: 100%;
  padding-bottom: 8px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.card-row:last-child {
  padding-bottom: 0px;
}

.card-col {
  display: flex;
  flex-flow: column;
}

.card-col-img {
  display: flex;
  gap: 8px;
}

.card-col-textfield {
  display: flex;
  line-height: 20px;
  padding-bottom: 4px;
}

.card-col-textfield:last-child {
  padding-bottom: 0px;
}

.back-button {
    padding: 4px 16px;
    border: 1.5px solid var(--border-1, #E2E2E2);
    border-radius: 4px;
    color: var(--blue-2, #315285);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
}

.team-card-header {
    padding: 8px 16px;
    border-radius: 8px 8px 0px 0px;
    border: 1px solid var(--dark-6, #E0E1E6);
    border-width: 0 0 1px 0;
    background: var(--dark-8, #F4F6F7);
    height: 40px;
}

.team-card-header-title {
    color: var(--dark-1, #0E1E40);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    text-transform: uppercase;
}

.arrow-button {
    border-radius: 4px;
    border: 1px solid var(--dark-6, #E0E1E6);
}

.arrow-button.arrow-disabled {
    opacity: 0.5;
}

.MuiCardHeader-action {
    margin: 0;
}

.average-activity-value {
    color: var(--dark-1);
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.average-activity-text {
    color: var(--dark-4);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.focus-text {
    color: var(--dark-2, #293855);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.color-circle {
    width: 8px;
    height: 8px;
    border: none;
    border-radius: 100%;
}

.color-deep {
    background-color: var(--blue-1, #213D69);
}

.color-normal {
    background-color: var(--blue-4, #5A81BC);
}

.color-low {
    background-color: var(--dark-5, #B5BAC4);
}

.color-work-break {
  background-color: var(--yellow-2, #F19E41);
}

.engagement-progress-bar {
    position: relative;
    width: 100%;
    height: 16px;
    border-radius: 10px;
    background: var(--dark-7, #ECECF0);
    display: flex;
    justify-content: center;
}

.engagement-progress-bar .middle-area {
    background: var(--yellow-2, #F19E41);
    width: 10%;
    height: 16px;
}

.progress-label {
    color: var(--dark-1, #0E1E40);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 19px;
}

.progress-value {
    color: var(--green-3, #53A551);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    transform: translateX(-50%);
    width: max-content;
}

.progress-arrow::before {
    content: '';
    transform: translateX(-50%);
    border-color: transparent transparent var(--green-3, #53A551) transparent;
    width: 8px;
    height: 11px;
    display: block;
    border-style: solid;
    border-width: 3px 4px 8px 4px;
}

.value-bar {
    position: absolute;
    padding-left: 2%;
    padding-right: 2%;
}
.value-stick {
    width: 1px;
    height: 16px;
    background: var(--green-3, #53A551);
}

.team-engagement-search-input {
    font-size: 14px !important;
    height: 28px !important;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-top: -2px;
}

.team-engagement-search-icon {
    top: 12px !important;
}

.width-max {
    width: 100%
}

.delivery-time {
  margin-top: 24px;
}

.delivery-time .rs-picker-toggle-textbox {
  background-color: #F9F9F9;
}

.delivery-time .rs-picker-toggle-read-only {
  opacity: 1;
  font-weight: 400;
}
