.org-logo {
    width: 24px;
    height: 24px;
    gap: 0px;
    opacity: 0px;
}

.org-manager-image {
    width: 80px;
    height: 80px;
    border-radius: 4px;
}

.org-name {
    color: var(--Primary-Brand-Blue, #4062E5);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.org-role {
    color: var(--Content-Content-01, #131414);
    text-align: center;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 160% */
}

.org-location {
    color: var(--Content-Content-01, #131414);
    text-align: center;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 140% */
}

.department-logo-wrapper {
    width: 80px;
    height: 80px;
    padding: 16.67px;
    background-color: white;
    border-radius: 100%;
}

.tree-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
}

.department-logo {
    width: 46.667px;
    height: 46.667px;
    flex-shrink: 0;
    border-radius: 100%;
}

.member-image {
    width: 56px;
    height: 56px;
    border-radius: 4px;
}

.member-card-info {
    display: flex;
    padding: 4px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    background: var(--Dark-Dark-11-White, #FFF);
}

.card-info-text {
    color: var(--Dark-Dark-02, #293855);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 9px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.card-info-time {
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.card-info-time[data-type="worked"] {
    color: var(--Green-Green-03-Branding, #53A551);
}

.card-info-time[data-type="idle"] {
    color: var(--Yellow-Yellow-02, #F19E41);
}

.card-info-time[data-type="expected"] {
    color: var(--Red-Red-02, #CC4525);
}

.org-page-navbar {
    display: flex;
    padding: 20px 32px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    background: var(--Dark-Dark-11-White, #FFF);
    box-shadow: 0px 0px 12px 0px rgba(9, 30, 66, 0.10);
    margin-bottom: 5px;
}

.org-page-caption {
    color: var(--Dark-Dark-01, #0E1E40);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
}

.org-page-wrapper {
    margin-top: -10px;
    margin-left: -32px;
    margin-right: -32px;
}