h1, h2, h3, h4, h5, h6 {
  color: #213D69;
}

h1 {
  font-size: 28px;
  line-height: 41px;
  font-weight: 700;
}

h2 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}

h6 {
  font-size: 14px;
  font-weight: 600;
  line-height: 32px;
}

p {
  color: #546077;
  font-size: 14px;
  line-height: 24px;
}

.info {
  font-size: 14px;
  line-height: 24px;
  color: #546077;
}

.info-item svg {
  margin-top: 2px;
  min-width: 20px;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 100%;
  font-weight: 600;
  border-radius: 8px;
  transition: all ease .3s;
}

h1 .orange {
  color: #F19235;
  border-radius: 8px;
  background: #F192351A;
  padding: 0 3px;
}

.partners {
  display: flex;
  justify-content: flex-start;
  gap: 40px;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scroll-padding: var(--outer);
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 32px 0;
}

@media (min-width: 576px) {
  h1 {
    font-size: 36px;
    line-height: 54px;
  }

  h2 {
    font-size: 32px;
    line-height: 41px;
  }

  h6 {
    font-size: 16px;
  }

  p {
    font-size: 16px;
    line-height: 28px;
  }

  .btn {
    height: 60px;
    width: 200px;
  }

  .partners {
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;
    padding: 48px 0;
  }
}

@media (min-width: 992px) {
  h1 {
    font-size: 52px;
    line-height: 65px;
  }

  h2 {
    font-size: 40px;
    line-height: 50px;
  }

  h6 {
    font-size: 18px;
  }

  p {
    font-size: 18px;
    line-height: 32px;
  }

  .info {
    font-size: 16px;
    line-height: 24px;
  }

  h1 .orange {
    padding: 0 11px;
  }

  .partners {
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;
    padding: 64px 0;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.hero-image {
  max-width: 100%;
  height: auto;
}

.btn-primary {
  background: #53A551;
  border: none;
}

.btn-link {
  color: #141F39;
}

.btn-primary:hover {
  background: #53A551;
  opacity: .8;
}

.btn-link:hover {
  color: #141F39;
  text-decoration: none;
  opacity: .8;
}

.features h2 {
  max-width: 750px;
}

.features-item {
  padding: 20px;
}

.shadow {
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 7px 38px 0px rgba(40, 65, 183, 0.10);
}

.header {
  height: 56px;
  position: sticky;
  top: 0;
  background: #FFFFFF;
  z-index: 10;
  border-bottom: 1px solid #D8E6FC;
  backdrop-filter: blur(2px);
}

.mobile-header {
  height: 56px;
}

.nav-link {
  padding: 0 10px;
  color: #060C3C;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.btn-header {
  width: 130px;
  height: 50px;
}

.header .logo, .footer .logo {
  width: 106px;
  height: auto;
}

.quote-mark {
  opacity: .3;
  transform: translate(-40%, -50%);
  width: 96px;
  height: 78px;
}

.quote-mark path {
  fill: #A7C5F2;
}

@media (min-width: 576px) {
  .header {
    height: 72px;
  }

  .header .logo .footer .logo {
    width: 120px;
  }
}

@media (min-width: 992px) {
  .header {
    height: 98px;
    border-bottom: none;
  }

  .nav-link {
    padding: 0 20px;
    font-size: 16px;
  }

  .header .logo .footer .logo {
    width: 132px;
  }

  .quote-mark {
    width: 142px;
    height: 116px;
  }
}

.mobile-menu-wrapper {
  height: 100%;
  z-index: 11;
  width: 296px;
  background: #FFFFFF;
  top: 0;
  bottom: 0;
  left: 100%;
  transition: all ease .5s;
}

.mobile-menu.show .mobile-menu-wrapper {
  left: calc(100% - 296px);
}

.mobile-menu::before {
  content: '';
  position: absolute;
  inset: 0;
  background: #0009;
  z-index: 11;
  opacity: 0;
  visibility: hidden;
  transition: all ease .5s;
}

.mobile-menu.show::before {
  opacity: 1;
  visibility: visible;
}

.mobile-nav .nav-link {
  padding: 12px 0;
}

.mobile-nav {
  flex: 1;
}

.copyright {
  color: #213D69;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  opacity: 0.88;
}

.footer {
  padding-top: 50px;
  padding-bottom: 24px;
}

.footer-menus summary {
  list-style: none;
  color: #7D8698;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
}

.footer-menus summary .icon {
  display: none;
}

.footer-menus .nav-link {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  padding: 0;
}

.start {
  padding-top: 70px;
  padding-bottom: 70px;
}

.start h2 {
  color: #FFFFFF;
  max-width: 719px;
}

.start p {
  max-width: 953px;
  color: #FFFFFF;
}

.background {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.testimonial {
  background: #F3F8FE;
  padding: 72px 0 40px;
}

.testimonial-card {
  padding: 32px;
  border-radius: 8px;
  border: 1px solid var(--Neutral-300, #EFF0F6);
  background: var(--Neutral-100, #FFF);
  box-shadow: 0px 7px 38px 0px rgba(40, 65, 183, 0.10);
}

.testimonial-info {
  margin: 32px 0 24px;
}

.testimonial-info p {
  width: 0;
  flex: 1;
}

.dots {
  width: 80px;
  height: auto;
}

.top-left {
  top: 15px;
  left: 15px;
}

.bottom-right {
  bottom: 15px;
  right: 15px;
}

.testimonial-writer p {
  font-size: 14px;
}

.hero {
  padding-top: 16px;
  padding-bottom: 16px;
}

.signup {
  padding-top: 30px;
  padding-bottom: 30px;
}

.features {
  padding-top: 30px;
  padding-bottom: 30px;
}

.explore, .deploy {
  margin-top: 40px;
  margin-bottom: 40px;
}

@media (min-width: 576px) {
  .hero {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .signup {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .features {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .testimonial {
    background: #F3F8FE;
    padding: 82px 0 56px;
  }

  .explore, .deploy {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .dots {
    width: 160px;
  }

  .top-left {
    top: 48px;
    left: 48px;
  }

  .bottom-right {
    bottom: 48px;
    right: 48px;
  }

  .footer-menus {
    gap: 24px;
  }

  .footer-menus details {
    width: 144px;
  }

  .footer-summary {
    max-width: 298px;
  }
}

@media (min-width: 992px) {
  .hero {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .signup {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .features {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .testimonial {
    background: #F3F8FE;
    padding: 94px 0 72px;
  }

  .explore, .deploy {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .footer-menus summary {
    font-size: 16px;
  }

  .footer-menus .nav-link {
    font-size: 16px;
    line-height: 40px;
  }

  .testimonial-card-1 {
    max-width: 476px;
  }

  .testimonial-card-2 {
    max-width: 488px;
  }

  .testimonial-card-3 {
    max-width: 384px
  }
}

@media (max-width: 575px) {
  .footer-menus details {
    border-bottom: 1px solid #ECECF0;
    padding-bottom: 8px;
    margin-bottom: 16px;
  }

  .footer-menus details:last-of-type {
    border: none;
  }

  .footer-menus summary {
    color: #213D69;
    font-weight: 600;
  }

  .footer-menus details:not([open]) summary .icon-plus {
    display: block;
  }

  .footer-menus details[open] summary .icon-minus {
    display: block;
  }

  .quote-mark {
    transform: translate(-10%, -50%);
  }
}
