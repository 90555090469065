.exportAll-btn {
  margin-left: auto;
}

.info-section {
  background-color: #F4F6F7;
  display: flex;
  align-items: center;
  margin: 16px 0;
  padding: 7px 11px;
  border-radius: 4px;
}

.info-text {
  font-size: 14px;
  line-height: 20px;
  color: #315285;
  margin: 0 1px;
  padding: 5px;
}

.exportAllForm-btns {
  text-align: right;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
}