.reports-header {
  margin-top: 25px;
}

.hours-card {
  width: 32%;
}

.chart-card {
  margin-top: 20px;
}

.daily-activities-card {
  margin-top: 20px;
}

.webcam-card {
  margin-top: 20px;
}

.hours-card-header {
  padding: 8px 16px;
  border-radius: 8px 8px 0px 0px;
  border: 1px solid var(--dark-6, #E0E1E6);
  border-width: 0 0 1px 0;
  background: var(--dark-8, #F4F6F7);
  height: 40px;
}

.hours-card-header-title {
  color: var(--dark-1, #0E1E40);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  text-transform: uppercase;
}

.total-hours-label {
  font-size: 32px;
  color: #53A551;
}

.hours-card-content {
  padding: 12px 18px;
}

.activity-card-content {
  padding: 0px !important;
}

.export-table-btn {
  width: 132px;
  height: 32px;
  border: 1px solid #A7C5F2;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  color: #0E1E40;
  text-transform: none;
}

.daily-activities-header {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  font-weight: 500;
}

.daily-activities-container {
  margin-bottom: 30px;
}

.daily-activities-column {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  padding: 16px 0px;
  align-items: center;
}

.daily-activities-menu {
  cursor: pointer;
}

.daily-activities-menu .daily-activities-first {
  display: flex;
  align-items: center;
}

.daily-activities-first {
  grid-column: 1 / 5;
  padding-left: 16px;
}

.daily-activities-second {
  grid-column: 5 / 6;
}

.daily-activities-third {
  grid-column: 6 / 7;
}

.daily-activities-category {
  display: flex;
  gap: 20px;
  align-items: center;
}

.daily-activities-category div {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.webcam-header {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  font-weight: 500;
}

.webcam-container {
  margin-bottom: 30px;
}

.webcam-column {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  padding: 16px 0px;
  align-items: center;
}

.webcam-menu {
  cursor: pointer;
}

.webcam-menu .webcam-first {
  display: flex;
  align-items: center;
}

.webcam-first {
  grid-column: 1 / 4;
  padding-left: 16px;
}

.webcam-second {
  grid-column: 4 / 5;
}

.webcam-third {
  grid-column: 5 / 6;
}

.webcam-firth {
  grid-column: 6 / 7;
}

.webcam-category {
  display: flex;
  gap: 20px;
  align-items: center;
}

.webcam-category div {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter-btn {
  width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #E0E1E6;
}

.open-filter-btn {
  background-color: #53A551;
  color: #fff;
}

.filter-footer {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;;
}
