.recharts-legend-item-text {
  color: #0E1E40 !important;
}

.tooltip-index-circle {
  margin-right: 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.tooltip-content-line {
  display: flex;
  align-items: center;
  width: 180px;
}

.tooltip-text {
  display: flex;
  justify-content: space-between;
  width: 160px;
}
