.search-section {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 24px;
  margin-bottom: 32px;
  padding: 16px 24px; 
  border-radius: 8px;
  box-shadow: 0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 3px 5px 0px rgba(9, 30, 66, 0.20);
  
}

.select-user {
  width: 30%;
  color: var(--dark-4);
  border: 1px solid var(--dark-7);
  border-radius: 4px;
}

.date-range {
  width: 55%;
  padding: 10px 12px; 
  color: var(--dark-4);
  border: 1px solid var(--dark-7);
  border-radius: 4px;
}

.search-btn {
  width: 15%;
  height: 46px !important; 
  padding: 10px 12px; 
  color: var(--white);
  border-radius: 4px;
}

.active-status {
  background-color: var(--green-3);
  padding: 8px;
  color: var(--white);
  border: 1px solid var(--green-3);
  border-radius: 6px;
}

.inactive-status {
  background: var(--white);
  padding: 8px;
  color: var(--dark-2);
  border: 1px solid var(--dark-4);
  border-radius: 3.31px;
  font-weight: 500;
  font-size: 14px;
  width: 100%;
  max-width: 250px;
}

.disabled {
    color: var(--dark-5);
    border-color:var(--dark-5);
}

.switch {
  color: #696969;
  margin-bottom: 16px;
}

.noResult-text {
  margin: 0 11px;
  font-size: 15px;
  white-space: nowrap;
  color: var(--dark-4);
}