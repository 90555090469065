:root {
  /* Use Case */
  --primary: #213D69;
  --text-color: #0E1E40;
  --error: var(--red-2);
  --success: var(--green-2);
  /* Background */
  --background-1: #FFF;
  --background-2: #F8F8F8;
  --background-3: #A1A1A1;
  /* Border */
  --border-1: #E2E2E2;
  --border-2: #CBCBCB;
  --border-3: #EEE;
  /* Dark */
  --dark-1: #0E1E40;
  --dark-2: #293855;
  --dark-3: #546077;
  --dark-4: #7D8698;
  --dark-5: #B5BAC4;
  --dark-6: #E0E1E6;
  --dark-7: #ECECF0;
  --dark-8: #F4F6F7;
  --dark-9: #FBFBFC;
  --dark-10: #FAFAFA;
  --white: #FFFFFF;
  /* Blue */
  --blue-1: #213D69;
  --blue-2: #315285;
  --blue-3: #4469A1;
  --blue-4: #5A81BC;
  --blue-5: #769BD3;
  --blue-6: #A7C5F2;
  --blue-7: #D8E6FC;
  /* Red */
  --red-1: #B0361A;
  --red-2: #CC4525;
  --red-3: #EB6240;
  /* Green */
  --green-1: #256D23;
  --green-2: #378A35;
  --green-3: #53A551;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0;
}

button {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-shadow: none;
  border: none;
}

.color-success {
  color: var(--success);
}

.color-text {
  color: var(--text-color);
}

.color-dark3 {
  color: var(--dark-3);
}

.in-block {
  display: inline-block;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-700 {
  font-weight: 700;
}

.text-600 {
  font-weight: 600;
}

.text-500 {
  font-weight: 500;
}

.text-400 {
  font-weight: 400;
}

.text-300 {
  font-weight: 300;
}

.underline {
  text-decoration: underline;
}

.size-s {
  font-size: 16px;
}

.size-xs {
  font-size: 14px;
}

.size-xxs {
  font-size: 12px;
}

.underline {
  text-decoration: underline;
}

.size-s {
  font-size: 16px;
}

.size-xs {
  font-size: 14px;
}

.success-msg {
  font-size: 13px;
  color: var(--success);
  margin-top: 10px;
}

.error-msg {
  font-size: 13px;
  color: var(--error);
  margin-top: 10px;
}

/* ===================== Flex ===================== */
.flex-row {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.jstfy-s {
  justify-content: flex-start;
}

.jstfy-e {
  justify-content: flex-end;
}

.jstfy-c {
  justify-content: center;
}

.align-s {
  align-items: flex-start;
}

.align-e {
  align-items: flex-end;
}

.align-c {
  align-items: center;
}

/* ===================== Margins ===================== */
.m-auto {
  margin: 0 auto;
}

.m-t0 {
  margin-top: 0px;
}

.m-t4 {
  margin-top: 4px;
}

.m-t8 {
  margin-top: 8px;
}

.m-t12 {
  margin-top: 12px;
}

.m-t16 {
  margin-top: 16px;
}

.m-t20 {
  margin-top: 20px;
}

.m-t24 {
  margin-top: 24px;
}

.m-b0 {
  margin-bottom: 0px;
}

.m-b4 {
  margin-bottom: 4px;
}

.m-b8 {
  margin-bottom: 8px;
}

.m-b12 {
  margin-bottom: 12px;
}

.m-b16 {
  margin-bottom: 16px;
}

.m-b20 {
  margin-bottom: 20px;
}

.m-l0 {
  margin-left: 0px;
}

.m-l4 {
  margin-left: 4px;
}

.m-l8 {
  margin-left: 8px;
}

.m-l12 {
  margin-left: 12px;
}

.m-l16 {
  margin-left: 16px;
}

.m-l20 {
  margin-left: 20px;
}

.m-r0 {
  margin-right: 0px;
}

.m-r4 {
  margin-right: 4px;
}

.m-r8 {
  margin-right: 8px;
}

.m-r12 {
  margin-right: 12px;
}

.m-r16 {
  margin-right: 16px;
}

.m-r20 {
  margin-right: 20px;
}

/* ===================== Padding ===================== */
.p-auto {
  padding: auto;
}

.p-0 {
  padding: 0;
}

.p-4 {
  padding: 4px;
}

.p-8 {
  padding: 8px;
}

.p-12 {
  padding: 12px;
}

.p-16 {
  padding: 16px;
}

.p-20 {
  padding: 20px;
}

.p-t0 {
  padding-top: 0;
}

.p-t4 {
  padding-top: 4px;
}

.p-t8 {
  padding-top: 8px;
}

.p-t12 {
  padding-top: 12px;
}

.p-t16 {
  padding-top: 16px;
}

.p-t20 {
  padding-top: 20px;
}

.p-b0 {
  padding-bottom: 0;
}

.p-b4 {
  padding-bottom: 4px;
}

.p-b8 {
  padding-bottom: 8px;
}

.p-b12 {
  padding-bottom: 12px;
}

.p-b16 {
  padding-bottom: 16px;
}

.p-b20 {
  padding-bottom: 20px;
}

.p-l0 {
  padding-left: 0;
}

.p-l4 {
  padding-left: 4px;
}

.p-l8 {
  padding-left: 8px;
}

.p-l12 {
  padding-left: 12px;
}

.p-l16 {
  padding-left: 16px;
}

.p-l20 {
  padding-left: 20px;
}

.p-r0 {
  padding-right: 0;
}

.p-r4 {
  padding-right: 4px;
}

.p-r8 {
  padding-right: 8px;
}

.p-r12 {
  padding-right: 12px;
}

.p-r16 {
  padding-right: 16px;
}

.p-r20 {
  padding-right: 20px;
}

.width-full {
  width: 100% !important;
}

.width-448 {
  width: 448px;
}

.position-relative {
  position: relative;
}