:root {
  /* Overrides RSuite Datepicker component border: */
  --rs-border-primary: var(--sds-input-border);
  --header-height: 56px;
  --sidenav-width: 250px;
}

* {
  font-family: 'Inter', sans-serif;
}

::-webkit-scrollbar {
  width: 8px;  /* width of the entire scrollbar */
  height: 8px; /* height of the vertical scrollbar */
}
/* Track of the scrollbar */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* color of the tracking area */
  border-radius: 10px;
}

/* Handle of the scrollbar */
::-webkit-scrollbar-thumb {
  background: #888;      /* color of the scroll thumb */
  border-radius: 10px;   /* roundness of the scroll thumb */
  border: none; /* creates padding around scroll thumb */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* color of the scroll thumb on hover */
}

/* Firefox only scrollbar properties */
@supports (-moz-appearance:none) {
  html {
    scrollbar-color: #888 #f1f1f1; /* thumb and track color */
    scrollbar-width: thin;
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: auto !important;
  -moz-osx-font-smoothing: auto !important;
}


code {
  font-family: Inter;
}


.icon-size-logo {
  height: 50px;
  width: 50px;
}

.App {
  text-align: center;
}

.shoot-logo {
  padding: .5rem 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#page {
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  width: 100%;
  transform: translateX(0);
}

#page #page-contents {
  margin: 0 auto;
}

#page-contents .card {
  background-color: #fff;
  border-radius: .25rem;
  box-shadow: 0 1px 0 rgba(0, 0, 0, .25);
  padding: 2rem 2rem 1rem;
  margin: 0 auto 2rem;
  position: relative;
  border: 1px solid #ddd;
}

.break-word {
  word-wrap: break-word;
}

.medium-bottom-margin {
  margin-bottom: 1.5rem !important;
}

.float-none {
  float: none !important;
}

.small-top-margin {
  margin-top: .5rem !important;
}

.span-4-of-6 {
  width: 66.13% !important;
}

.span-5-of-6 {
  width: 83.06%;
}

.small-margin {
  margin: .5rem !important;
}

.bold {
  font-weight: bold;
}

.large-bottom-padding {
  padding-right: 2rem !important;
}

.large-right-padding {
  padding-right: 2rem !important;
}

.large-left-padding {
  padding-right: 2rem !important;
}

.large-bottom-margin {
  margin-bottom: 2rem !important;
}

.no-bottom-margin {
  margin-bottom: 0 !important;
}

.small-right-margin {
  margin-right: .5rem !important;
}

.align-right {
  text-align: right !important;
}

.small-bottom-margin {
  margin-bottom: .5rem !important;
}

.large-bottom-margin {
  margin-bottom: 2rem !important;
}

.shoot-large-input {
  font-size: 1.25rem !important;
  line-height: normal !important;
  padding: .75rem !important;
  border: 1px solid #868686 !important;
  border-radius: .25rem !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  outline: 0 !important;
  color: #1d1c1d !important;
  width: 100% !important;
  max-width: 100% !important;
  margin: 0 0 .5rem !important;
  font-variant-ligatures: none !important;
  -webkit-transition: box-shadow 70ms ease-out, border-color 70ms ease-out !important;
  -moz-transition: box-shadow 70ms ease-out, border-color 70ms ease-out !important;
  transition: box-shadow 70ms ease-out, border-color 70ms ease-out !important;
  box-shadow: none !important;
  height: auto !important;
}

.btn-large-shoot {
  background: #8BC53F !important;
  color: #fff !important;
  transition: all 75ms ease-in-out !important;
  line-height: 1.2rem !important;
  font-weight: 900 !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  text-decoration: none !important;
  cursor: pointer !important;
  text-shadow: none !important;
  border: none !important;
  border-radius: .25rem !important;
  box-shadow: none !important;
  position: relative !important;
  display: inline-block !important;
  vertical-align: bottom !important;
  text-align: center !important;
  white-space: nowrap !important;
  padding: 14px 32px 16px !important;
  font-size: 20px !important;
  margin: 0 !important;
  -webkit-tap-highlight-color: transparent !important;
}

.btn-large-shoot:hover {
  color: #fff;
}

.btn-large-shoot:disabled {
  cursor: default !important;
}

.full-width {
  width: 100% !important;
  max-width: 100% !important;
}

.screen-wrapper {
  height: 100vh;
  overflow-x: hidden;
  background-color: white;
}

.screen-header {
  margin: 66.73px 32px 0 282px;
}

@media(max-width:'1260px') {
  .screen-header {
    padding: 0 0 !important;
  }
}

.screen-body {
  margin: 0 32px 0 282px;
}

.screen-header-container {
  display: flex;
  margin: 1.6px 0 16px;
  justify-content: space-between;
}

.shoot-dd-menu .shoot-dd-item:hover {
  background-color: #1264a3;
  color: #fff;
}

.shoot-dd-menu .shoot-dd-item-danger:hover {
  background-color: #B0361A;
  color: #fff !important;
}

.shoot-light-btn {
  background-color: #fff !important;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .3);
}

.shoot-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 4;
  cursor: pointer;
}

.loader-container {
  position: absolute;
  left: calc(50% + 141px);
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.signup-header {
  font-weight: 900;
  font-size: 25px;
  margin-bottom: 20px;
  margin-top: 4px;
  line-height: 1.2143;
  color: #1d1c1d;
  font-family: Inter;
}

.c-btn-green {
  display: block;
  font-size: 20px;
  font-weight: 900;
  height: 44px;
  min-width: 96px;
  padding: 0 16px 3px;
  transition: all 80ms linear;
  background: #8BC53F !important;
  color: #fff !important;
  border-color: #8BC53F;
  box-shadow: none;

  &:hover {
    border-color: #8BC53F;
  }
}

.full-width {
  width: 100% !important;
  max-width: 100% !important;
}

nav.top.persistent {
  background: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
}


.shoot-light-btn:hover {
  background-color: #fff !important;
  border: 1px solid black !important;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .3), 0 1px 4px rgba(0, 0, 0, .2);
}

.container-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

@media only screen and (min-width: 1024px) {
  #page-contents {
    width: 1024px;
    padding: 6rem 2rem;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}