.photo-wrapper {
  margin-bottom: 32px;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.photo-preview {
  width: 132px;
  height: 132px;
  border-radius: 8px;
}

.photo-upload-btn {
  display: none;
}
