.alert-badge {
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.alert-badge[data-status="Pending"] {
  border: 1px solid #4469A155;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #4469A1;
  color: #4469A1;
}

.alert-badge[data-status="Resolved"] {
  border: 1px solid #A6EAA5;
  background: #CFF6CE;
  color: #256D23;
}

.alert-badge[data-status="Acknowledged"] {
  border: 1px solid #F5DAD3;
  background: #FCECE7;
  color: #CC4525;
}

.alert-badge[data-status="Low"] {
  border: 1px solid #F5DAD3;
  background: #FCECE7;
  color: #CC4525;
}

.alert-badge[data-status="Average"] {
  border: 1px solid #E68B02;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #E68B02;
  color: #E68B02;
}

.alert-badge[data-status="High"] {
  border: 1px solid #A6EAA5;
  background: #CFF6CE;
  color: #256D23;
}

.alert-status-badge {
  color: #FFFFFF;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.alert-status-badge[data-status="Pending"] {
  background: #4469A1;
}

.alert-status-badge[data-status="Resolved"] {
  background: #256D23;
}

.alert-status-badge[data-status="Acknowledged"] {
  background: #CC4525;
}

.alert-date-badge {
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid #D8E6FC;
  background: #FAFAFA;
  color: #213D69;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.alert-avatar {
  width: 72px;
  height: 72px;
  border-radius: 100%;
  background-color: #53A55116;
  display: flex;
  justify-content: center;
  align-items: center;
}

.alert-avatar-letter {
  color: #53A551;
}

.alert-modal-container {
  padding: 12px 8px;
}

.alert-user-name {
  color: var(--Dark-Dark-01, #0E1E40);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.alert-modal-view-btn {
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--Dark-Dark-06, #E0E1E6);
  background: var(--Dark-Dark-11-White, #FFF);
  color: var(--Blue-Blue-01, #213D69);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  text-decoration: none;
}

.alert-modal-view-btn:hover {
  text-decoration: none;
}

.alert-user-info-title {
  align-self: stretch;
  color: var(--Dark-Dark-03, #546077);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.alert-user-info-content {
  color: var(--Dark-Dark-01, #0E1E40);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.border-bottom-div {
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: var(--Dark-Dark-06, #E0E1E6);
}

.alert-icon {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.alert-description-text {
  align-self: stretch;
  color: var(--Color-4, #061232);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.alert-resend-btn {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;

  border-radius: 4px;
  border: 1px solid var(--Dark-Dark-06, #E0E1E6);
  background: var(--Dark-Dark-11-White, #FFF);

  color: var(--Blue-Blue-01, #213D69);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
}

.alert-mark-btn {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 4px;
  background: var(--Green-Green-03-Branding, #53A551);
  color: #FFF;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
}

.filter-btn {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  gap: 4px;
  width: auto;

  border-radius: 4px;
  border: 1px solid var(--Border-Border-01, #E2E2E2);
  background: var(--Primary-White, #FFF);

  color: var(--Blue-Blue-03, #4469A1);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.filter-panel{
  width: 286px;
}

.filter-labe {
  color: var(--Content-Content-02, #545454);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.14px;
}

.filter-reset-btn {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 4px;
  border: 1px solid var(--Dark-Dark-04, #7D8698);
  background: var(--Dark-Dark-11-White, #FFF);

  color: var(--Dark-Dark-02, #293855);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 100% */
}

.filter-search-btn {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 4px;
  background: var(--Green-Green-03-Branding, #53A551);

  color: #FFF;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 100% */
}

.team-name-column {
  color: var(--Dark-Dark-01, #0E1E40);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}