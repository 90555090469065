.member-table-wrapper {
  position: relative;
}

.member-table-wrapper .asc-sort-icon {
  padding-left: 6px;
}

.member-table-wrapper .desc-sort-icon {
  transform: scaleY(-1);
  padding-left: 6px;
}

.btn-member-setting {
  padding: 0 .75rem !important;
}

#dropdown-basic.btn-member-setting {
  font-size: unset !important;
  line-height: unset;
  display: flex;
}

.table td {
  padding: 8px;
}

.table th.header-title {
  padding-left: 8px !important;
}

.table td,
.table th {
  vertical-align: middle !important;
}

.btn-member-setting:after {
  content: none !important
}

.btn-member-setting:focus {
  box-shadow: none !important;
}

.main-wrapper {
  background-color: #E5E5E5;
}

.table tbody {
  font-style: normal;
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #546077;
}

th {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #546077;
  border: none !important;
  background: #FAFAFA;
  border-radius: 4px;
}

.dot-class {
  content: "";
  display: inline-block;
  width: 4px;
  height: 4px;
  background-color: #868686;
  border-radius: 50%;
  vertical-align: middle;
  margin: 0 4px;
}

.screen-header-container {
  flex-wrap: wrap;
}

.screen-header-container .row {
  margin: 0 !important;
  max-width: 900px;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
}

.from-date-wrapper,
.to-date-wrapper,
.drop {
  margin-bottom: 3px;
}

.wrap-filters {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.wrap-filters>div {
  margin-right: 15px;
}

.from-date {
  padding-top: 6px;
  padding-bottom: 6px;
  margin-bottom: 0px;
  height: 37px;
}

.to-date {
  padding-top: 6px;
  padding-bottom: 6px;
  height: 37px;
}

.shoot-dd-menu-accountType {
  background: #FAFAFA;
  border: 1px solid #5A81BC;
  box-sizing: border-box;
  box-shadow: 0px 8px 12px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  position: absolute;
  top: -6px !important;
  transform: translate3d(-231px, 19px, 0) !important;
  will-change: transform;
  color: #0E1E40;
  align-items: center;
  font-weight: 400 !important;
  font-size: 14px !important;
  left: 85px !important;
}

.shoot-dd-menu {
  background: #FAFAFA;
  border: 1px solid #5A81BC;
  box-shadow: 0px 8px 12px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  color: #0E1E40;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.dropdown-item {
  padding: 2px 8px !important;
}

.changeRole {
  line-height: 24px !important;
  font-size: 16px !important;
}

.accountTypeDrop {
  margin-left: 5px;
  color: #546077d1;
  font-size: 11px;
}

.dropdown-options {
  background-color: #FAFAFA;
  border-radius: 4px;
  width: 32px;
  padding: 3px 10px;
}

.managememberheading {
  padding-top: 48px;
}

.exportAllBtn:hover,
.exportAllBtn:focus {
  color: white !important;
  border-color: #28a745;
  background-color: #28a745 !important;
}

.export-all-user {
  width: 100%;
  padding-top: 28px;
}

.header-manage-member {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: #0E1E40;
  width: 1136px;
  height: 40px;
}

.btn-reset {
  align-items: center;
}

.exportAllBtn {
  right: 0;
}

.newMemberBtn i {
  width: 20px;
  font-size: 13px;
}

.newMemberSection .searchIcon {
  width: 20px;
  font-size: 14px;
  color: #7D8698 !important;
  position: absolute;
  left: 22px;
  top: 53%;
  transform: translateY(-50%);
  z-index: 1;
  padding-left: 6px;
}

.newMemberSection input[type='text'] {
  background: white;
  border: 1px solid #ECECF0;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 16px;
  line-height: 16px;
  color: #7D8698;
  width: 316px;
  height: 40px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  position: relative;
  padding-left: 30px;
  color: black;
}

.newMemberBtn {
  right: 0;
  color: #FFFFFF !important;
  background-color: #429256 !important;
  margin-right: 16px;
}

.deactivate-model-title {
  font-family: Inter;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #0E1E40;
}

.newMember-model-title {
  font-family: Inter;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #0E1E40;
}

.modal-content {
  background: #FFFFFF;
  box-shadow: 0px 18px 28px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 8px !important;
  padding: 32px !important;
}

#newMemberAccount .modal-header {
  padding: 0 0 16px 0 !important;
  border-bottom: none !important;
}

.modal-header {
  padding: 0 0 24px 0 !important;
  border-bottom: none !important;
}

.main-sub {
  background: #F4F6F7;
  border-radius: 4px;
  margin-bottom: 47px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #315285;
}

.deactivate-btns {
  text-align: right;
}

.validationMsg {
  margin: 3px 0 32px 0;
  font-size: 13px;
  color: red;
}

.successMsg {
  font-size: 13px;
  color: var(--green-2);
  margin-top: 10px;
}

.newMemberForm input:focus,
.newMemberForm select:focus {
  color: #495057;
  background-color: #fff;
  border-color: #080808;
  outline: 0;
  box-shadow: 0 0 0 0.1rem white;
}

.newMemberForm-btns {
  text-align: right;
}

.btn-addMember {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #FFFFFF !important;
  background: #429256 !important;
  border-radius: 4px;
  padding: 8px 24px !important;
  margin-left: 24px;
  height: 44px;
}

.btn-addMember:hover {
  color: #FFFFFF !important;
  background-color: #28a745 !important;
}

.btn-deactivate {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #FFFFFF !important;
  background: #CC4525 !important;
  border-radius: 4px;
  padding: 8px 24px !important;
  margin-left: 24px;
  height: 44px;
}

.btn-deactivate:hover {
  color: #FFFFFF !important;
  background-color: #9f3a22 !important;
}

.btn-settings {
  width: 78px;
  height: 44px;
  border: 1px solid black !important;
  padding: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  margin: 0px !important;
}

.info-subText {
  font-size: 14px;
  color: #315285;
  margin: 0 1px;
  padding: 5px;
}

.main-sub span {
  font-family: Inter;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px;
  line-height: 20px;
  color: #315285;
}

#deactivateAccount,
#newMemberAccount {
  margin-top: 205px;
  width: 482px;
  height: 290px;
}

.newMemberForm label {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #545454;
  margin: 4px 0px;
}

.newMemberForm input {
  border: none;
  border-bottom: 1px solid black;
  border-radius: 0px;
  padding: 0px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #545454;
}

.newMemberForm select {
  border: none;
  border-bottom: 1px solid black;
  border-radius: 0px;
  padding: 0px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #545454;
}

.modal-description {
  font-family: Inter;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 24px;
  color: #293855;
}

.drop {
  height: calc(1.5em + .75rem + 4px);
}

.newMemberSection {
  background: #FFFFFF;
  border-radius: 8px;
  margin: 0 32px 0 282px;
  position: relative;
  padding: 22px 16px 16px 16px;
  display: flex;
  justify-content: space-between;
}

.export-selection-tracking-container {
  margin-bottom: 30px;
  flex-direction: row;
  display: flex;
  text-align: justify;
  align-items: center;
}

.date-pickers-export {
  flex-direction: row;
  padding-left: 0px !important;
}

.date-pickers-export img {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translate(6px, -50%);
  background: #FFFFFF;
  border: 1px solid #ECECF0;
  box-sizing: border-box;
  border-radius: 4px;
}

.reports-options p {
  color: black;
  font-weight: 500;
}

.list-of-options {
  display: flex;
  justify-content: space-between;
  color: black;
  font-weight: 400;
  font-family: Inter;
  font-style: normal;
  font-size: 14px;
  margin-right: 100px;
  flex-wrap: wrap;
}

.form-check {
  width: 100%;
  max-width: 315px;
}

.reports-options-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.export-All-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
}