.subMenuUl .subMenuItem {
  padding-left: 25px;
  position: relative;
}

.subMenuUl .subMenuItem .link-text {
  padding: 8px 0 8px 16px !important;
  margin-right: 24px;
}

.subMenuUl .subMenuItem .link-text:hover {
  background: #ececf0;
  border-radius: 4px;
}

.subMenuUl .subMenuItem .active-link {
  background: #ececf0;
  border: 0.5px solid #e0e2ec;
  border-radius: 4px;
}

.subMenuUl .subMenuItem:first-child::before {
  height: 18px;
}

.subMenuUl .subMenuItem::before {
  content: "";
  position: absolute;
  left: 10px;
  transform: translateY(-50%);
  width: 10px;
  height: 36px;
  border: solid #ececf0;
  border-width: 0 0 2px 2px;
}

.link-text i {
  padding-left: 60px;
  padding-right: 15px;
}

.link-item:hover {
  text-decoration: none;
  color: #213D69;
}