.total-card-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1%;
}
.total-card {
  display: flex;
  border: 1px solid #ECECF0;
  border-radius: 6px;
  padding: 12px;
}
.total-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  width: 60%;
}
.table-wrapper {
  width: 40%;
}
.total-title {
  font-size: 12px;
  line-height: 16px;
}
.total-number {
  font-size: 20px;
  font-weight: 600;
}
.total-change {
  display: flex;
  align-items: center;
}
.total-change p {
  font-size: 10px;
  color: #7D8698;
}
.total-increase span {
  color: #378A35;
}
.total-decrease span {
  color: #E7322C;
}
.custom-tooltip {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  transform: translate(-50%, -20%); /* Adjust tooltip position to be above the cursor */
}
.two-card-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1%;
}
.one-card-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}
.table-card-logo p {
  font-size: 14px;
  font-weight: 600;
}


.navbar-holder {
  height: var(--header-height);
  position: fixed;
  padding: 0.8rem 24px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, .15);
  z-index: 10;
  width: 100%;
  background-color: #fff;
}

.navitem-brand {
  font-size: 25px;
}

.nav-link-text {
  color: #0056b3 !important;
}

.navbar-center {
  align-items: center;
  text-align: center;
}

.navbar-between {
  display: flex;
  justify-content: space-between;
}

.user-menu {
  display: flex;
  color: #1d1c1d;
  cursor: pointer;
}