.twofactor-main {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.twofactor-wrapper {
  padding: 30px;
  width: 500px;
  background-color: #FAFAFA;
  border: 1px solid #c5bbbb;
}

.twofactor-img {
  display: flex;
  justify-content: center;
}

.twofactor-img img {
  width: 100px;
  margin-top: 20px;
}

.twofactor-wrapper p {
  text-align: center;
  margin-top: 25px;
  font-size: 28px;
  font-weight: 450;
}

.twofactor-form {
  background: white;
  padding: 30px;
  border-radius: 8px;
  border: 1px solid #dbcccc;
  display: flex;
  flex-direction: column;
}

.twofactor-form text {
  font-size: 18px;
  font-weight: bold;
}

.twofactor-form button {
  height: 45px;
  margin-top: 21px;
}

.twofactor-form input[type='text'] {
  height: 44px;
  margin-top: 10px;
}

.twofactor-footer {
  margin: 10px 30px 20px 30px;
  display: flex;
  justify-content: space-between;
}

.twofactor-footer text {
  font-size: 20px;
  font-weight: 500;
  margin-left: 10px;
}

.twofactor-footer i {
  font-size: 30px;
}

.errorMsg {
  color: red;
  font-size: 16px;
  margin: 5px 0;
}

.twofactor-main .newCodeMsg {
  cursor: pointer;
}

.twofactor-main .newCodeMsg:hover {
  text-decoration: underline;
}

.btn-link {
  color: #007bff;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
