.plans-table td, .plans-table.table th.header-title {
  border: 1px solid #E0E1E6!important;
  padding: 16px !important;
}

.plans-table th {
  background: #FFFFFF;
  text-align: left !important;
}

.plans-table tr:nth-child(2n-1) {
  background: #F5F7FE;
}

.plans-table {
  border-radius: 8px;
}

.plans-table td {
  color: #293855;
}

.plans-table .feature {
  color: #0E1E40;
}

.plans-table .description {
  color: #293855;
}

.plans-table .price {
  font-size: 24px;
}

.plans-table {
  table-layout: fixed;
}

.recommended-badge {
  color: var(--Dark-Dark-11-White, #FFF);
  font-size: 14px;
  line-height: 20px;
  padding: 2px 8px;
  border-radius: 4px;
  background: var(--Blue-Blue-03, #4469A1);
}

.btn-primary {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  text-transform: none;
  border-radius: 4px;
  padding: 7px 29px;
  color: #53A551;
  border: 1px solid;
  width: 100%;
  background-color: #FFFFFF;
}

.btn-second {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  text-transform: none;
  border-radius: 4px;
  padding: 7px 29px;
  color: #FFFFFF;
  border: 1px solid;
  width: 100%;
  background-color: #53A551;
}

.btn-second:hover {
  background-color: #53A551;
  color: #FFFFFF;
  border-color: #53A551;
}

.btn-primary:hover {
  background-color: #FFFFFF;
  color: #53A551;
  border-color: #53A551;
}
