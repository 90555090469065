.screen-header.insights {
  display: flex;
  flex-flow: column;
  height: calc(100% - 66.73px);
}

.reports-container {
  display: flex;
  flex: 1 0 0;
}

.reports-wrapper {
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 16px;
  flex-direction: column;
  gap: 24px;
  flex: 1;
  border-top: 1px solid #E0E1E6;
}