.auditStatus-btn {
  width: 146px;
  padding: 8px 0;
  border-radius: 5px;
  border: 1px solid #ECECF0;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  transition: all 0.3s;
}

.incomplete {
  background-color: #F19235;
}

.completed {
  background-color: #53A551;
}

.no-audits {
  background-color: var(--dark-5);
}

.texts-section {
  color: var(--text-color);
}

.searchBox {
  float: right;
  position: relative;
}

.searchBox input[type='text'] {
  background: white;
  border: 1px solid #ECECF0;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 16px;
  line-height: 16px;
  color: #7D8698;
  width: 316px;
  height: 40px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  position: relative;
  padding-left: 30px;
  color: black;
}

.searchBox .searchIcon {
  width: 20px;
  font-size: 14px;
  color: #7D8698 !important;
  position: absolute !important;
  left: 5px;
  top: 20px;
  transform: translateY(-50%);
  z-index: 1;
  padding-left: 6px;
}