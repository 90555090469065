.dashboard-container {
  position: absolute;
  right: 0;
  width: 90%;
  margin-right: -47px;
}

.content-area {
  padding: 70px;
}

.create-organization-container {
  border: solid 1px lightgray;
  padding-top: 3%;
  border-radius: 10px;
  padding-right: 25%;
  padding-left: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
}

.title-text {
  font-family: 'Inter';
  font-size: 24px;
  font-weight: bolder;
  color: #131414;
}

.small-text {
  color: #0E1E40;
  padding: 12px;
}

.explain-text {
  float: left;
  padding: 10px;
  color: #293855;
  font-weight: bold;
  font-size: 14px;
}

.final-text {
  float: left;
  /* padding: 10px; */
  color: #293855;
  font-weight: bold;
  font-size: 14px;
  margin-top: 15px;
  margin-left: 27px;
}

.two-buttons {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  border-top: solid 1px lightgray;
  padding: 30px;
  justify-content: space-between;
  align-items: center;
  margin-left: -50%;
  margin-right: -50%;
}

.navbar-holder {
  height: var(--header-height);
  position: fixed;
  padding: 0.8rem 24px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, .15);
  z-index: 10;
  width: 100%;
  background-color: #fff;
}

.navitem-brand {
  font-size: 25px;
}

.nav-link-text {
  color: #0056b3 !important;
}

.navbar-center {
  align-items: center;
  text-align: center;
}

.navbar-between {
  display: flex;
  justify-content: space-between;
}

.user-menu {
  display: flex;
  color: #1d1c1d;
  cursor: pointer;
}