.two-factor-row {
    padding-left: 50px;
    position: relative;
}

.two-factor-row::before {
    content: "";
    position: absolute;
    left: 10px;
    transform: translateY(-50%);
    width: 20px;
    height: 30px;
    border: solid #ececf0;
    border-width: 0 0 2px 2px;
}

.MuiTypography-root {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}
.logo-wrapper .photo-wrapper {
    margin-bottom: 0px;
    align-items: flex-start;
    margin-top: 10px;
}
.logo-wrapper {
    padding-bottom: 15px;
    border-bottom: 1px solid;
    border-color: rgba(0, 0, 0, 0.12);
}
.logo-wrapper .no-logo {
    font-size: 16px;
    font-weight: 400;
    margin: 10px 0px;
}
.workspace-delete {
    padding-top: 25px;
}
.headline-delete {
    margin-top: 20px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
}
.desc-delete {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    width: 640px;
}
.button-delete {
    width: 188px;
    height: 40px;
    border-radius: 4px;
    background-color: #CC4525;
    margin-top: 20px;
    text-transform: none;
    font-size: 16px;
    font-weight: 500;
}
.button-delete:hover {
    background-color: #CC4525;
}
.button-remove:hover {
    background-color: #CC4525;
}

.time-off-subheader {
    color: var(--dark-1, #0E1E40);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}
.table td {
    padding: 0.75rem;
    height: 48px;
}

.table th.header-title {
    padding-left: 8px !important;
}

.table td,
.table th {
    vertical-align: middle !important;
}

.btn-member-setting:after {
    content: none !important
}

.btn-member-setting:focus {
    box-shadow: none !important;
}

.main-wrapper {
    background-color: #E5E5E5;
}

.table tbody {
    font-style: normal;
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #546077;
}

th {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    height: 48px;
    color: #131414;
    border: none !important;
    background: #FAFAFA;
    border-radius: 4px;
}

.balance-row {
    cursor: pointer;
}

.balance-row:hover {
    background: #FAFAFA;
}


/* width */
#offTimePolicyModal::-webkit-scrollbar {
  width: 12px;
}

/* Track */
#offTimePolicyModal::-webkit-scrollbar-track {
  background: #FCFCFC;
  border-right: 1px solid #E8E8E8;                                
  border-left: 1px solid #E8E8E8;
}

/* Handle */
#offTimePolicyModal::-webkit-scrollbar-thumb {
  background: #7A7A7A;
  border-radius: 100px;
}

/* Handle on hover */
#offTimePolicyModal::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.holidayForm-btns {
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

.custom-date-icon .rs-picker-toggle-caret,
.hide-date-icon .rs-picker-toggle-caret {
  /* Hide the default caret icon */
  display: none;
  cursor: pointer;
}

.custom-date-icon .rs-picker-toggle::after {
  content: '';
  display: block;
  width: 20px; /* Width of your SVG */
  height: 20px; /* Height of your SVG */
  background-image: url('../../images/datePickerIcon.svg');
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  right: 10px; /* Adjust to match your design */
  top: 50%;
  transform: translateY(-50%);
}

/*
.custom-date-icon .rs-picker-default .rs-picker-toggle.rs-btn,
.hide-date-icon .rs-picker-default .rs-picker-toggle.rs-btn {
  background-color: #f9f9f9 !important;
}
*/